<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.OracleConfiguration">
        <h1 class="title">{{ $t("entities.oracleConfiguration") }}</h1>

        <template slot="actions">
          <button
            @click="synchronizeProjects"
            class="button is-warning"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
          >
            {{ $t("oracle.synchronizeProjects") }}
          </button>
        </template>
      </page-header>

      <div class="notification is-danger is-light">
        Attention, le changement de valeur de configuration Oracle a un impact
        direct sur l'envoi des commandes et receptions dans Oracle.
      </div>

      <datatable
        ref="oracleConfigurations"
        :fetch="$api.fetchOracleConfigurations"
        :enableDownload="false"
        objectName="oracle_configuration"
        identifier="code"
      >
        <div
          slot="code"
          slot-scope="{ item }"
          :title="$t('common.code')"
          :sortable="true"
        >
          {{ item.code }}
        </div>
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('common.name')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div
          slot="description"
          slot-scope="{ item }"
          :title="$t('common.description')"
          :sortable="true"
        >
          {{ item.description }}
        </div>
        <div
          slot="value"
          slot-scope="{ item }"
          :title="$t('common.value')"
          :sortable="true"
        >
          {{ item.value }}
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link
            :to="'/oracleconfiguration/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
      <div class="mt-5">
        <button
          class="button is-small is-default"
          @click="$router.push('/tasks')"
        >
          Tasks
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OracleConfigurationList",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    synchronizeProjects() {
      this.isLoading = true;
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir synchroniser les projets Oracle ?",
        () => {
          axios.post("/oracle/projects/sync").then((response) => {
            if (response.data.success) {
              this.$awn.success("Synchronisation des projets Oracle en cours !");
            } else {
              this.$awn.error("Erreur lors de la synchronisation des projets Oracle !");
            }
          });
          this.isLoading = false;
        }
      );
    },
  },
};
</script>
