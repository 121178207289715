<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.Project"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title">
          <breadcrumb
            location="/projects"
            :text="$t('common.project')"
          ></breadcrumb>
          {{ project.name }}
        </h1>
      </page-header>
      <div class="panel" cy-data="cy-project-title">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                @click="currentTab = 0"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                @click="currentTab = 1"
              >
                <a>{{ $t("entities.users") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 2 }"
                @click="currentTab = 2"
              >
                <a>{{ $t("common.imputations") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 3 }"
                @click="currentTab = 3"
              >
                <a>{{ $t("common.oracle_projects") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 4 }"
                @click="currentTab = 4"
              >
                <a>{{ $t("common.documents") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 5 }"
                @click="currentTab = 5"
              >
                <a>{{ $t("entities.oscarSubProjects") }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel-block no-border is-block" v-if="currentTab === 0">
          <text-field
            cy-data="name"
            :label="$t('common.name')"
            v-model="project.name"
            required
          ></text-field>
          <text-field
            cy-data="code"
            :label="$t('common.code')"
            v-model="project.code"
          ></text-field>
          <many2one-field
            :label="$t('projects.columns.requisitioningBU')"
            v-model="project.oracleBusinessUnitRequisitioning"
            :fetch="$api.fetchBusinessUnits"
            reference="name"
            entity="business_unit"
            :columns="{
              name: $t('common.name'),
              description: $t('common.description'),
              invoiceLocation: $t('common.invoiceLocation'),
              deliveryLocation: $t('common.deliveryLocation'),
              supplyChainOrganization: $t('common.supplyChainOrganization'),
            }"
            :edit="editMode"
            :hasFilter="true"
          ></many2one-field>
          <selector-field
            :label="$t('common.type')"
            :options="$api.fetchProjectTypesAsList"
            v-model="project.type"
            cy-data="type"
          >
            <template slot-scope="{ option }">{{ option.name }}</template>
          </selector-field>
          <text-field
            cy-data="manager"
            :label="$t('common.manager')"
            v-model="project.manager"
          ></text-field>
          <textarea-field
            cy-data="address"
            :label="$t('common.contactInformation')"
            v-model="project.address"
          ></textarea-field>
          <selector-field
            :label="$t('projects.monthlyGenerationDay')"
            :options="fetchDays"
            pkey="value"
            v-model="project.msGenerationDate"
            cy-data="monthly-statement-generation-day"
          >
          </selector-field>
          <many2one-field
            :label="$t('entities.paymentGuarantee')"
            v-model="project.paymentGuarantee"
            :fetch="$api.fetchPaymentGuarantees"
            reference="templateName"
            entity="project_payment_guarantee_template"
            :columns="{ templateName: $t('entities.paymentGuarantee') }"
            :edit="editMode"
            :hasFilter="true"
            data-test="sel-project-payment-guarantee-template"
          ></many2one-field>
          <checkbox-field
            :label="$t('projects.shareFiles')"
            :value="project.shareFiles"
            v-model="project.shareFiles"
          />
        </div>

        <div class="panel-block no-border is-block" v-if="currentTab === 1">
          <div class="is-pulled-right">
            <button
              v-if="getRights($entitiesName.UserProject).create"
              @click.stop="openAddUserModal"
              slot="activator"
              class="button is-success"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>
                  <span>
                    <i
                      class="fa fa-cloud-download"
                      @click.stop="$refs.download.click()"
                    ></i>
                  </span>
                </th>
                <th>{{ $t("common.name") }}</th>
                <th>{{ $t("common.company") }}</th>
                <th>{{ $t("common.email") }}</th>
                <th>{{ $t("common.role") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="users.length == 0">
                <td style="text-align: center" colspan="8">
                  {{ $t("expressions.noUser") }} :)
                </td>
              </tr>
              <tr v-for="(u, index) in users" :key="index">
                <td>#{{ index + 1 }}</td>
                <td>{{ u.user.name }}</td>
                <td>
                  <span v-if="u.user.primaryAgency">{{
                    u.user.primaryAgency.company.name
                  }}</span>
                </td>
                <td>{{ u.user.mail }}</td>
                <td>{{ u.user.role.name }}</td>
                <td>
                  <button
                    v-if="getRights($entitiesName.UserProject).delete"
                    class="button is-small is-danger"
                    @click="removeUser(u.user)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="panel-block no-border is-block" v-if="currentTab === 2">
          <div class="is-pulled-right">
            <button
              v-if="getRights($entitiesName.Imputation).create"
              class="button is-success"
              @click="openCreateImputationModal"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <datatable
            :fetch="$api.fetchImputationsByProject.bind(this, id)"
            key="imputations"
            ref="imputationsTable"
            :enableDownload="true"
            :objectName="`project/${this.id}/imputations`"
          >
            <div slot="eotp" slot-scope="{ item }" :title="$t('common.eotp')">
              {{ item.eotp }}
            </div>
            <div
              slot="description"
              slot-scope="{ item }"
              :title="$t('common.designation')"
            >
              {{ item.description }}
            </div>
            <div
              slot="nbOsValidatedOrClosed"
              slot-scope="{ item }"
              :title="$t('projects.nbOrdersValidated')"
              nofilter
              class="nowrap has-text-right"
            >
              {{ item.nbOsValidatedOrClosed }}
            </div>
            <div
              slot="nbAttachementPayed"
              slot-scope="{ item }"
              :title="$t('projects.nbAttachmentsPaid')"
              nofilter
              class="nowrap has-text-right"
            >
              {{ item.nbAttachementPayed }}
            </div>
            <div
              slot="totalOsValidatedOrClosed"
              slot-scope="{ item }"
              :title="$t('projects.sumOrdersValidated')"
              nofilter
              class="nowrap has-text-right"
            >
              {{ item.totalOsValidatedOrClosed | priceEUR }}
            </div>
            <div
              slot="totalAttachementPayed"
              slot-scope="{ item }"
              :title="$t('projects.sumAttachmentsPaid')"
              nofilter
              class="nowrap has-text-right"
            >
              {{ item.totalAttachementPayed | priceEUR }}
            </div>
            <div
              slot="active"
              slot-scope="{ item }"
              :title="$t('common.active')"
              nofilter
              switcher
              class="nowrap has-text-centered"
            >
              <checkbox-field
                :value="item.active"
                v-model="item.active"
                :inline="false"
              />
            </div>
            <div slot="actions" slot-scope="{ item }">
              <button
                v-if="getRights($entitiesName.Imputation).update"
                class="button is-small is-primary"
                @click="editImputation(item)"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                v-if="getRights($entitiesName.Imputation).delete"
                class="button is-small is-danger"
                @click="removeImputation(item)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </datatable>
        </div>

        <div class="panel-block no-border is-block" v-if="currentTab === 3">
          <div class="is-pulled-right">
            <button
              v-if="getRights($entitiesName.OracleProject).create"
              class="button is-success"
              @click="openAddOracleProjectModal"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <datatable
            :fetch="$api.fetchOracleProjectsByProject.bind(this, id)"
            key="oracleProjects"
            ref="oracleProjectsTable"
            :enableDownload="false"
          >
            <div slot="name" slot-scope="{ item }" :title="$t('common.name')">
              {{ item.name }}
            </div>

            <div
              slot="typeName"
              slot-scope="{ item }"
              :title="$t('common.type')"
            >
              {{ item.typeName }}
            </div>

            <div
              slot="unitName"
              slot-scope="{ item }"
              :title="$t('common.unit')"
            >
              {{ item.unitName }}
            </div>

            <div
              slot="status"
              slot-scope="{ item }"
              :title="$t('common.status')"
            >
              {{ item.status }}
            </div>

            <div
              slot="businessUnitName"
              slot-scope="{ item }"
              :title="$t('common.businessUnit')"
            >
              {{ item.businessUnitName }}
            </div>

            <div
              slot="owningOrganizationName"
              slot-scope="{ item }"
              :title="$t('common.owningOrganizationName')"
            >
              {{ item.owningOrganizationName }}
            </div>

            <div
              slot="legalEntityName"
              slot-scope="{ item }"
              :title="$t('common.legalEntity')"
            >
              {{ item.legalEntityName }}
            </div>

            <div
              slot="startDate"
              slot-scope="{ item }"
              :title="$t('common.startDate')"
            >
              {{ item.startDate }}
            </div>

            <div
              slot="endDate"
              slot-scope="{ item }"
              :title="$t('common.endDate')"
            >
              {{ item.endDate }}
            </div>

            <div
              slot="managerName"
              slot-scope="{ item }"
              :title="$t('common.manager')"
            >
              {{ item.managerName }}
            </div>

            <div
              slot="description"
              slot-scope="{ item }"
              :title="$t('common.description')"
            >
              {{ item.description }}
            </div>

            <div slot="actions" slot-scope="{ item }">
              <button
                v-if="getRights($entitiesName.OracleProject).delete"
                class="button is-small is-danger"
                @click="removeOracleProject(item)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </datatable>
        </div>

        <div class="panel-block no-border is-block" v-if="currentTab === 4">
          <document-manager
            :objectName="$entitiesName.Project"
            :objectId="id"
            :deletable="getRights($entitiesName.Project).update"
          ></document-manager>
        </div>

        <div class="panel-block no-border is-block" v-if="currentTab == 5">
          <datatable
            :fetch="$api.fetchOscarSubProjectsByProject.bind(this, id)"
            key="pm"
            ref="pmsTable"
            identifier="id"
            :enableDownload="false"
            objectName="pm"
          >
            <div slot="name" slot-scope="{ item }" :title="$t('common.name')">
              {{ item.name }}
            </div>
            <div
              slot="arcgisName"
              slot-scope="{ item }"
              :title="$t('oscarSubProjects.columns.arcgisName')"
            >
              {{ item.arcgisName }}
            </div>
            <div slot="nroName" slot-scope="{ item }" :title="$t('common.nro')">
              {{ item.nroName }}
            </div>
            <div
              slot="ipeIdPm"
              slot-scope="{ item }"
              :title="$t('oscarSubProjects.columns.nroIpeId')"
            >
              {{ item.ipeIdPm }}
            </div>
            <div
              slot="offerIdPm"
              slot-scope="{ item }"
              :title="$t('oscarSubProjects.columns.pmOfferId')"
            >
              {{ item.offerIdPm }}
            </div>

            <div slot="actions" slot-scope="{ item }">
              <restricted-link
                :entity="$entitiesName.OscarSubProject"
                :to="'/oscarsubproject/' + item.id"
                container="span"
                linkClass="button is-primary is-small"
              >
                <i class="fa fa-search"></i>
              </restricted-link>
            </div>
          </datatable>
        </div>

        <modal
          ref="createImputationModal"
          @create="createImputation"
          :title="$t('projects.imputationCreation')"
        >
          <div class="columns">
            <div class="column is-half">
              <text-field
                :label="$t('common.eotp')"
                v-model="imputation.eotp"
                :inline="false"
                :edit="true"
                required
                :validate="{ min: project.code && project.code.length + 1 }"
              />
              <textarea-field
                :label="$t('common.description')"
                v-model="imputation.description"
                :inline="false"
                :edit="true"
              />
            </div>
          </div>
        </modal>

        <modal
          ref="addOracleProjectModal"
          @create="addOracleProject"
          :title="$t('projects.oracleProjectCreation')"
          :action="$t('common.add')"
        >
          <datatable
            :fetch="fetchAvailableOracleProjects"
            v-model="addOracleProjectVal"
            identifier="projectId"
            :size="15"
          >
            <div
              slot="name"
              slot-scope="{ item }"
              :title="$t('common.name')"
            >
              {{ item.name }}
            </div>

            <div
              slot="typeName"
              slot-scope="{ item }"
              :title="$t('common.type')"

            >
              {{ item.typeName }}
            </div>

            <div
              slot="unitName"
              slot-scope="{ item }"
              :title="$t('common.unit')"
            >
              {{ item.unitName }}
            </div>

            <div
              slot="status"
              slot-scope="{ item }"
              :title="$t('common.status')"
            >
              {{ item.status }}
            </div>

            <div
              slot="businessUnitName"
              slot-scope="{ item }"
              :title="$t('common.businessUnit')"
            >
              {{ item.businessUnitName }}
            </div>

            <div
              slot="owningOrganizationName"
              slot-scope="{ item }"
              :title="$t('common.owningOrganizationName')"
            >
              {{ item.owningOrganizationName }}
            </div>

            <div
              slot="legalEntityName"
              slot-scope="{ item }"
              :title="$t('common.legalEntity')"
            >
              {{ item.legalEntityName }}
            </div>

            <div
              slot="startDate"
              slot-scope="{ item }"
              :title="$t('common.startDate')"
            >
              {{ item.startDate }}
            </div>

            <div
              slot="endDate"
              slot-scope="{ item }"
              :title="$t('common.endDate')"
            >
              {{ item.endDate }}
            </div>

            <div
              slot="managerName"
              slot-scope="{ item }"
              :title="$t('common.manager')"
            >
              {{ item.managerName }}
            </div>

            <div
              slot="description"
              slot-scope="{ item }"
              :title="$t('common.description')"
            >
              {{ item.description }}
            </div>
          </datatable>
        </modal>

        <modal
          ref="updateImputationModal"
          @create="updateImputation"
          :title="$t('projects.editImputation')"
          action="Modifier"
        >
          <div class="columns">
            <div class="column is-half">
              <text-field
                :label="$t('common.eotp')"
                v-model="imputation.eotp"
                :inline="false"
                :edit="true"
                required
                :validate="{ min: project.code && project.code.length + 1 }"
              />
              <textarea-field
                :label="$t('common.description')"
                v-model="imputation.description"
                :inline="false"
                :edit="true"
              />
              <checkbox-field
                :label="$t('common.active')"
                :edit="true"
                :value="imputation.active"
                v-model="imputation.active"
                :inline="false"
              />
            </div>
          </div>
        </modal>

        <modal
          ref="addUserModal"
          @create="addUser"
          :title="$t('projects.addUserToProject')"
        >
          <datatable
            single-select
            :fetch="$api.fetchUsers"
            v-model="addUserVal"
            :columns="columnsUser"
            :size="15"
          ></datatable>
        </modal>
      </div>

      <div class="panel" v-if="currentTab == 0">
        <panel-header
          :title="$t('entities.oscarProjects')"
          @add="addOscarProject"
          :fetch="$api.fetchOscarProjects"
          :columns="{ name: $t('entities.oscarProject') }"
          :hasFilter="true"
        >
          <button
            slot="activator"
            data-test="sel-user-add-imputation"
            class="button is-small is-success"
          >
            <i class="fa fa-plus"></i>
          </button>
        </panel-header>
        <div class="panel-block">
          <table class="table is-fullwidth">
            <!-- <thead>
              <tr>
                <td>Imputation</td>
                <td></td>
              </tr>
            </thead> -->
            <tbody>
              <tr v-for="(op, index) in project.oscarProjects" :key="index">
                <td class="has-text-left">{{ op.name }}</td>
                <td>
                  <div class="is-pulled-right">
                    <button
                      class="button is-small is-danger"
                      @click="removeOscarProject(op)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <form
      v-if="currentTab == 1"
      :style="{ display: 'none' }"
      target="_blank"
      :action="`${API}/project/${this.id}/users/csv`"
      method="POST"
    >
      <button type="submit" ref="download"></button>
      <input type="hidden" name="token" :value="authToken" />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { globalLocale } from "../../i18n";

export default {
  name: "project",
  props: ["id"],
  data() {
    return {
      project: {},
      users: [],
      imputation: {
        project_id: this.id,
      },
      columnsUser: {
        name: {
          title: this.$t("common.name"),
          filter: "",
        },
        mail: {
          title: this.$t("common.email"),
          filter: "",
        },
        "role.name": "Role",
        service: {
          title: "Service",
          filter: "",
        },
        quality: {
          title: this.$t("common.quality"),
          filter: "",
        },
      },
      columnsPM: {
        ipe_id_pm: {
          title: "ID IPE",
          filter: "",
        },
        offer_id_pm: {
          title: "ID Offre",
          filter: "",
        },
      },
      addUserVal: null,
      addPMVal: null,
      addOracleProjectVal: null,
      currentTab: 0,
      objectName: "",
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      globalLocale: globalLocale,
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      user: "auth/getUser",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/project/${this.id}`).then((response) => {
        this.project = response.data;
        this.project.msGenerationDate = response.data.msGenerationDate
          ? response.data.msGenerationDate.toString()
          : "";
        this.imputation.eotp = this.project.code;
        this.$store.dispatch("states/setEdition", false);
      });

      axios.get(`/project/${this.id}/users`).then((response) => {
        this.users = response.data;
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData();
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.$refs.imputationsTable) {
            axios.put("/imputations", this.$refs.imputationsTable.items);
          }

          return axios
            .put(`/project/${this.id}`, this.project)
            .then((response) => {
              if (response.data.success) {
                this.fetchData();
              }
            });
        }
        return Promise.resolve();
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(`/project/${this.id}/delete`, this.project)
            .then((response) => {
              if (response.data.success) {
                this.$router.replace("/projects");
              }
            })
      );
    },
    openAddUserModal() {
      this.$refs.addUserModal.open();
    },
    addUser() {
      return axios
        .post(`/project/${this.project.id}/user/${this.addUserVal.id}`)
        .then(() => {
          this.addUserVal = null;
          this.$refs.addUserModal.close();
          this.fetchData();
        });
    },
    removeUser(user) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios
            .delete(`/project/${this.project.id}/user/${user.id}`)
            .then(() => {
              this.fetchData();
            });
        }
      );
    },
    openAddOracleProjectModal() {
      this.$refs.addOracleProjectModal.open();
    },
    addOracleProject() {
      const oracleProject = this.addOracleProjectVal;

      // Check that the oracleProject is an array
      if(!Array.isArray(oracleProject)) {
        return;
      }

      const promises = [];

      oracleProject.forEach((project) => {
        promises.push(axios.put(`/project/${this.id}/oracleprojects/${project.id}`))
      })

      Promise.all(promises).then(() => {
          this.addOracleProjectVal = null;
          this.$refs.addOracleProjectModal.close();
          this.$refs.oracleProjectsTable.refresh();
        });
    },
    fetchAvailableOracleProjects(p) {
      return this.$api.fetchAvailableOracleProjectsByProject(this.id, p);
    },
    removeOracleProject(project) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/project/${this.id}/oracleprojects/${project.id}`).then(() => {
            this.$refs.oracleProjectsTable.refresh();
          });
        }
      );
    },
    openCreateImputationModal() {
      this.$refs.createImputationModal.open();
    },
    editImputation(item) {
      this.imputation = item;
      this.imputation.project = this.project;
      this.$refs.updateImputationModal.open();
    },
    createImputation() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .post("/imputation/create", {
              project: this.project,
              eotp: this.imputation.eotp,
              description: this.imputation.description,
            })
            .finally(() => {
              this.imputation = {};
              this.$refs.createImputationModal.close();
              this.$refs.imputationsTable.refresh();
            });
        }
      });
    },
    updateImputation() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line
          axios
            .put(`/imputation/${this.imputation.id}`, this.imputation)
            .finally(() => {
              this.imputation = {};
              this.$refs.updateImputationModal.close();
              this.$refs.imputationsTable.refresh();
            });
        }
      });
    },
    removeImputation(item) {
      const imputationId = item.id;
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/imputation/${imputationId}/delete`).finally(() => {
            this.fetchData();
            this.$refs.imputationsTable.refresh();
          });
        }
      );
    },
    downloadCSV(currentTab) {
      this.objectName = currentTab === 1 ? "users" : "imputations";
      this.$refs.download.click();
    },
    fetchDays(callback) {
      const days = [
        "",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
      ];
      if (typeof callback === "function") {
        callback(days);
      }
      return days;
    },
    /* Oscar Projets */
    addOscarProject(op) {
      axios.post(`/project/${this.id}/oscarproject/${op.id}`).then(() => {
        this.fetchData();
      });
    },
    removeOscarProject(op) {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/project/${this.id}/oscarproject/${op.id}`).then(() => {
            this.fetchData();
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.no-border {
  border: none;
}

.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.is-full-width {
  flex: 1;
}

.fa-cloud-download {
  color: #019fc4;
  font-size: 24px;
}
</style>
